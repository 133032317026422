@import 'declarations';
@import 'utilities';
// The stuff below should be moved into their respective places wherever that might be

:root {
  --py-compensation-width: 0px;
}
body {
  width: 100%;
  height: 100vh;
  min-height: 100vh; /* this property is inherited by child nodes */
  overflow-x: hidden;
  overflow-y: scroll;

  &.locked {
    overflow: hidden;

    // Extending the width of header to cover the scrollbar gap that is visible when overflow:hidden while content width is preserved on body
    // Adding paddings to header and search to avoid resizing the content
    header.storefront-header {
      width: calc(100% + var(--py-compensation-width)) !important;
      padding-right: calc(1rem + var(--py-compensation-width)) !important;

      @include media-breakpoint-up(xl) {
        padding-right: calc(2rem + var(--py-compensation-width)) !important;
      }
    }

    py-search {
      &.tablet-view,
      &.mobile-view {
        padding-right: calc(1rem + var(--py-compensation-width)) !important;
      }
    }
  }
}

i.reset {
  display: inline-flex;
  align-items: center;
  line-height: 0;
}

/* Hide scrollbar */
.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.py-max-width {
  max-width: 1600px;
  margin: 0 auto;
}

/* Hide visual search in Edge */
img.no-visual-search,
.no-visual-search img {
  pointer-events: none;
}

.grecaptcha-badge {
  z-index: 1;
}
